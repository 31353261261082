<template>
  <div><visit-log-list ref="visitlog" /></div>
</template>

<script>

import VisitLogList from '@/components/visitLog/VisitLogList.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    VisitLogList,
  },
  mounted() {
    this.updateNavbar({ showGoalPicker: true, showDatePicker: true })

    this.getVisitLogData()

    this.$eventBus.$on('filterChanges', () => {
      this.getVisitLogData()
    })
  },
  methods: {
    ...mapActions('appConfig', {
      updateNavbar: 'updateNavbarConfig',
    }),
    ...mapActions('visits', {
      clearVisitLog: 'clearVisitLog',
    }),
    getVisitLogData() {
      this.clearVisitLog()
      this.$refs.visitlog.fetchData()
    },
  },
}
</script>
